// eslint-disable-next-line import/named
import { axiosCloudService, axiosInstance, axiosSendGrid } from '@/helpers/axiosInstance'
import router from '@/router'

// eslint-disable-next-line no-unused-vars
async function uploadFile(data, token) {
  console.log('data', data)
  let file1 = null
  let file2 = null
  let file3 = null
  let file4 = null
  const headers = {
    'Content-Type': 'application/json',
    'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
    // eslint-disable-next-line no-useless-concat
    Authorization: `Bearer ${token}`,
  }
  const formData = new FormData()
  formData.append('file', data.image_id)
  console.log('data', data)
  // eslint-disable-next-line camelcase
  const image_id = await axiosInstance.post('/api/v1/files/upload', formData, { headers })
  if (data.image_1 !== '') {
    const formData2 = new FormData()
    formData2.append('file', data.image_1)
    file1 = await axiosInstance.post('/api/v1/files/upload', formData2, { headers })
  }
  if (data.image_2 !== '') {
    const formData3 = new FormData()
    formData3.append('file', data.image_2)
    file2 = await axiosInstance.post('/api/v1/files/upload', formData3, { headers })
  }
  if (data.image_3 !== '') {
    const formData4 = new FormData()
    formData4.append('file', data.image_3)
    file3 = await axiosInstance.post('/api/v1/files/upload', formData4, { headers })
  }
  if (data.image_4 !== '') {
    const formData5 = new FormData()
    formData5.append('file', data.image_4)
    file4 = await axiosInstance.post('/api/v1/files/upload', formData5, { headers })
  }
  return {
    image_id,
    file1,
    file2,
    file3,
    file4,
  }
}
async function uploadFileCloud(data) {
  const headers = {
    'Content-Type': 'application/json',
  }
  const formData = new FormData()
  formData.append('file', data.file)
  formData.append('key', process.env.VUE_APP_X_API_KEY_SERVICE)
  // eslint-disable-next-line camelcase
  return await axiosCloudService.post('/api/wp/file-upload', formData, { headers })
}
export default {
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListProductCategory({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const merchant = JSON.parse(sessionStorage.getItem('MERCHANT'))
      await axiosInstance.get(`/api/v1/categories?merchant_id=${merchant.merchant_id}&limit=50`, { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          commit('SET_LIST_CATEGORY', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async createProductCategory({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      console.log('createProductCategory', data)

      const formData = new FormData()
      formData.append('file', data.image)
      // eslint-disable-next-line camelcase
      const image = await axiosInstance.post('/api/v1/files/upload', formData, { headers })
      // eslint-disable-next-line no-param-reassign
      data.image = image.data.data.id
      await axiosInstance.post('/api/v1/categories', data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Category Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product-category/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async getDetailProductCategory({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get(`/api/v1/categories/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async updateProductCategory({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      console.log('updateProductCategory', data)

      if (data.image !== '') {
        const formData = new FormData()
        formData.append('file', data.image)
        // eslint-disable-next-line camelcase
        const image = await axiosInstance.post('/api/v1/files/upload', formData, { headers })
        // eslint-disable-next-line no-param-reassign
        data.image = image.data.data.id
      }

      await axiosInstance.put(`/api/v1/categories/${data.id}`, data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response updateProductCategory', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update Category Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product-category/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListProduct({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const merchant = JSON.parse(sessionStorage.getItem('MERCHANT'))
      await axiosInstance.get(`/api/v1/products?merchant_id=${merchant.merchant_id}&limit=50`, { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          commit('SET_LIST_PRODUCT', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListRegion({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.get('/api/regions', { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          commit('SET_LIST_REGION', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListWPRegion({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.get('/api/wp/regions', { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          commit('SET_LIST_WP_REGION', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getPlanDetail({ commit, state }, id) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get(`/api/plans/${id}`, { headers })
        // eslint-disable-next-line consistent-return
        .then(response =>
          // If request is good...
          response.data)
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListServer({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.get('/api/wp/servers', { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          commit('SET_LIST_SERVER', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListCluster({ commit, state }, { regionID = null }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      // console.log('regionID', regionID)
      if (regionID == null) {
        await axiosInstance.get('/api/clusters', { headers })
        // eslint-disable-next-line consistent-return
          .then(response => {
            // If request is good...
            commit('SET_LIST_CLUSTER', response.data)
          })
          .catch(error => {
            console.log(error)
            return false
          })
      } else {
        await axiosInstance.get(`/api/clusters?region_id=${regionID}`, { headers })
        // eslint-disable-next-line consistent-return
          .then(response => {
            // If request is good...
            commit('SET_LIST_CLUSTER', response.data)
          })
          .catch(error => {
            console.log(error)
            return false
          })
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListWPCluster({ commit, state }, { regionID = null }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      // console.log('regionID', regionID)
      if (regionID == null) {
        await axiosInstance.get('/api/wp/clusters', { headers })
        // eslint-disable-next-line consistent-return
          .then(response => {
            // If request is good...
            commit('SET_LIST_WP_CLUSTER', response.data)
          })
          .catch(error => {
            console.log(error)
            return false
          })
      } else {
        await axiosInstance.get(`/api/wp/clusters?region_id=${regionID}`, { headers })
        // eslint-disable-next-line consistent-return
          .then(response => {
            // If request is good...
            commit('SET_LIST_WP_CLUSTER', response.data)
          })
          .catch(error => {
            console.log(error)
            return false
          })
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListClusterPlan({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      // console.log('regionID', regionID)
      return await axiosInstance.get('/api/cluster-plan', { headers })
        // eslint-disable-next-line consistent-return
        .then(response => response.data)
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getDetailWPCluster({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get(`/api/wp/clusters/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async getDetailWPSize({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get(`/api/wp/sizes/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async getDetailClusterPlan({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get(`/api/cluster-plan/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async getListSize({ commit, state }, { regionID = null }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      // console.log('regionID', regionID)
      if (regionID == null) {
        return await axiosInstance.get('/api/wp/sizes', { headers })
        // eslint-disable-next-line consistent-return
          .then(response => {
            // If request is good...
            commit('SET_LIST_SIZE', response.data)
            return response.data
          })
          .catch(error => {
            console.log(error)
            return false
          })
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  async getListPlan({ commit, state }, { regionID = null }) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // 'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      // console.log('regionID', regionID)
      if (regionID == null) {
        return await axiosInstance.get('/api/plans', { headers })
        // eslint-disable-next-line consistent-return
          .then(response => {
            // If request is good...
            commit('SET_LIST_PLAN', response.data)
            return response.data
          })
          .catch(error => {
            console.log(error)
            return false
          })
      }
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-empty-pattern
  async getListAttributeByProduct({ commit, state }, id) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.get(`/api/v1/products/${id}`, { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          console.log('response getListAttributeByProduct', response)
          // If request is good...
          commit('SET_LIST_ATTRIBUTE_BY_PRODUCT', response.data)
        })
        .catch(error => {
          console.log(error)
          return false
        })
    } catch (error) {
      console.log(error)
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async createProduct({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await uploadFile(data, sessionStorage.getItem('accessToken')).then(async res => {
        console.log('RES', res)
        const arrImages = []
        if (res.file1 != null) arrImages.push(res.file1.data.data.id)
        if (res.file2 != null) arrImages.push(res.file2.data.data.id)
        if (res.file3 != null) arrImages.push(res.file3.data.data.id)
        if (res.file4 != null) arrImages.push(res.file4.data.data.id)
        console.log(arrImages)
        // eslint-disable-next-line camelcase,no-param-reassign
        const merchant = JSON.parse(sessionStorage.getItem('MERCHANT'))
        // eslint-disable-next-line no-param-reassign
        data.image_id = res.image_id.data.data.id
        // eslint-disable-next-line no-param-reassign
        data.image_ids = arrImages
        // eslint-disable-next-line no-param-reassign
        data.tags = merchant.tags
        console.log('createProduct', JSON.stringify(data))
        await axiosInstance.post('/api/v1/products', data, { headers })
        // eslint-disable-next-line consistent-return
          .then(async response => {
            // If request is good...
            console.log('response', response)
            await dispatch('showNotification', {
              title: 'Success !', type: 'success', duration: 5000, text: 'Creat Product Success !',
            })
            await commit('SHOW_LOADING', false)
            setTimeout(() => {
              router.push('/apps/product/list')
            }, 1000)
          })
          .catch(error => {
            console.log(error)
            commit('SHOW_LOADING', false)
            dispatch('showNotification', {
              title: 'Error',
              type: 'error',
              duration: 7000,
              text: error.response.data.message,
            })
            return false
          })
      }).catch(e => {
        console.log('e', e)
      })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  // eslint-disable-next-line consistent-return,no-unused-vars
  async createAttribute({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const merchant = JSON.parse(sessionStorage.getItem('MERCHANT'))
      data.tags = merchant.tags
      console.log('DATA createAttribute', JSON.stringify(data))
      await axiosInstance.post(`/api/v1/products/${data.product_id}/attributes`, data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat attribute Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/product/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  // eslint-disable-next-line no-unused-vars
  async sendMailInvoice({ commit, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'key-mail': 'OELSL4Urj4',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${process.env.VUE_APP_KEY_SEND_GRID}`,
      }
      const obj = {
        personalizations: [{ to: [{ email: data.toMail }] }],
        from: { email: data.from, name: 'EPIS' },
        subject: data.subject,
        content: [{ type: 'text/html', value: `<a clicktracking='off' href='https://merchant-frontend.pages.dev/apps/invoice/preview-payment/${data.invoiceId}'>Click Here To Continue Pay Now !</a>` }],
      }
      await axiosSendGrid.post('/mail', obj, { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          // If request is good...
          console.log('RES', response)
          commit('SHOW_LOADING', false)
          alert('Check Email to continue pay now !')
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async updateTimeOpen({ commit, dispatch, state }, obj) {
    commit('SHOW_LOADING', true)
    try {
      console.log('obj updateTimeOpen', obj)
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post('/api/v1/auth/open', obj, { headers })
      // eslint-disable-next-line consistent-return
        .then(response => {
          commit('SHOW_LOADING', false)
          // If request is good...
          console.log('response updateTimeOpen', response)
          // commit('SET_LIST_CATEGORY', response.data)
          dispatch('showNotification', {
            title: 'Success',
            type: 'success',
            duration: 7000,
            text: 'Thành công !',
          })
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'Có lỗi xảy ra, vui lòng thử lại !',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'Có lỗi xảy ra, vui lòng thử lại !',
      })
      return false
    }
  },
  async updateProFile({ commit, dispatch, state }, obj) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const formData = new FormData()
      formData.append('file', obj.image_id)
      // eslint-disable-next-line camelcase
      const image = await axiosInstance.post('/api/v1/files/upload', formData, { headers })
      // eslint-disable-next-line no-param-reassign
      obj.image_id = image.data.data.id

      if (obj.area_category_ids == null) obj.area_category_ids = []
      if (obj.category_ids == null) obj.category_ids = []
      if (obj.global_category_ids == null) obj.global_category_ids = []

      await axiosInstance.post('/api/v1/auth/profile', obj, { headers })
        // eslint-disable-next-line consistent-return
        .then(response => {
          commit('SHOW_LOADING', false)
          // If request is good...
          // commit('SET_LIST_CATEGORY', response.data)
          dispatch('showNotification', {
            title: 'Success',
            type: 'success',
            duration: 7000,
            text: 'Thành công !',
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'Có lỗi xảy ra, vui lòng thử lại !',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'Có lỗi xảy ra, vui lòng thử lại !',
      })
      return false
    }
  },
  async getListProductAreaCategory({ commit, state }, {}) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-MONMI-API': process.env.VUE_APP_X_MONMI_API,
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get('/api/v1/area-categories?limit=1000', { headers })
    } catch (error) {
      console.log(error)
      return []
    }
  },
  async createRegion({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post('/api/regions', data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Region Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/region/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async createWPRegion({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post('/api/wp/regions', data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat WP Region Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/wp-region/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async getDetailWPRegion({ commit, dispatch, state }, data) {
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      return await axiosInstance.get(`/api/wp/regions/${data}`, { headers })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      return false
    }
  },
  async updateWPRegion({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post(`/api/wp/regions/${data.id}`, data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update WP Region Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/wp-region/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async deleteRegion({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/regions/${id}`, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async deleteWPRegion({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/wp/regions/${id}`, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async deleteServer({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/wp/servers/${id}`, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async deleteCluster({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/clusters/${id}`, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async deleteClusterPlan({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/cluster-plan/${id}`, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async deleteWPCluster({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/wp/clusters/${id}`, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async deleteSize({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/wp/sizes/${id}`, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async deletePlan({ commit, dispatch, state }, id) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.delete(`/api/plans/${id}`, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Deleted !',
          })
          await commit('SHOW_LOADING', false)
          window.location.reload()
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: 'error',
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'error',
      })
      return false
    }
  },
  async createCluster({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const pathFile = await uploadFileCloud(data)
      const formData = new FormData()
      formData.append('file', pathFile.data.result.Location)
      formData.append('name', data.name)
      formData.append('region_id', data.region)
      await axiosInstance.post('/api/clusters', formData, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Cluster Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/cluster/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async createWPCluster({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const pathFile = await uploadFileCloud(data)
      const formData = new FormData()
      formData.append('file', pathFile.data.result.Location)
      formData.append('name', data.name)
      formData.append('region_id', data.region)
      formData.append('server_id', data.server)
      formData.append('size_id', data.size)
      formData.append('limit', data.limit)
      // formData.append('disk', data.disk)
      // formData.append('containers', data.containers)
      await axiosInstance.post('/api/wp/clusters', formData, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat WP Cluster Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/wp-cluster/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'FAIL !',
      })
      return false
    }
  },
  async createClusterPlanAPI({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const pathFile = await uploadFileCloud(data)
      const formData = new FormData()
      formData.append('file', pathFile.data.result.Location)
      formData.append('name', data.name)
      formData.append('region_id', data.region_id)
      formData.append('plan_id', data.plan_id)
      formData.append('limit', data.limit)
      // formData.append('disk', data.disk)
      // formData.append('containers', data.containers)
      await axiosInstance.post('/api/cluster-plan', formData, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Cluster Plan Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/cluster-plan/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: 'FAIL !',
      })
      return false
    }
  },
  async updateWPCluster({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const formData = new FormData()
      console.log('data', data.file)
      if (data.file !== undefined && data.file !== '') {
        const pathFile = await uploadFileCloud(data)
        formData.append('file', pathFile.data.result.Location)
      }
      formData.append('name', data.name)
      formData.append('region_id', data.region)
      formData.append('server_id', data.server)
      formData.append('size_id', data.size)
      formData.append('limit', data.limit)
      // formData.append('disk', data.disk)
      // formData.append('containers', data.containers)
      await axiosInstance.post(`/api/wp/clusters/${data.id}`, formData, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update WP Cluster Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/wp-cluster/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async updateClusterPlan({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      const formData = new FormData()
      console.log('data', data.file)
      if (data.file !== undefined && data.file !== '') {
        const pathFile = await uploadFileCloud(data)
        formData.append('file', pathFile.data.result.Location)
      }
      formData.append('name', data.name)
      formData.append('region_id', data.region_id)
      formData.append('plan_id', data.plan_id)
      formData.append('limit', data.limit)
      await axiosInstance.post(`/api/cluster-plan/${data.id}`, formData, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update Cluster Plan Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/cluster-plan/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async createServer({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post('/api/wp/servers', data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          console.log('response', response)
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Server Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/server/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async createSize({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post('/api/wp/sizes', data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Size Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/size/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async updateWPSize({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post(`/api/wp/sizes/${data.id}`, data, { headers })
          // eslint-disable-next-line consistent-return
          .then(async response => {
            // If request is good...
            await dispatch('showNotification', {
              title: 'Success !', type: 'success', duration: 5000, text: 'Update Size Success !',
            })
            await commit('SHOW_LOADING', false)
            setTimeout(() => {
              router.push('/apps/size/list')
            }, 1000)
          })
          .catch(error => {
            console.log(error)
            commit('SHOW_LOADING', false)
            dispatch('showNotification', {
              title: 'Error',
              type: 'error',
              duration: 7000,
              text: error.response.data.message,
            })
            return false
          })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async createPlan({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post('/api/plans', data, { headers })
      // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Creat Plan Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/plan/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
  async updateAPIPlan({ commit, dispatch, state }, data) {
    commit('SHOW_LOADING', true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        // eslint-disable-next-line no-useless-concat
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      }
      await axiosInstance.post(`/api/plans/${data.id}`, data, { headers })
        // eslint-disable-next-line consistent-return
        .then(async response => {
          // If request is good...
          await dispatch('showNotification', {
            title: 'Success !', type: 'success', duration: 5000, text: 'Update Plan Success !',
          })
          await commit('SHOW_LOADING', false)
          setTimeout(() => {
            router.push('/apps/plan/list')
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          commit('SHOW_LOADING', false)
          dispatch('showNotification', {
            title: 'Error',
            type: 'error',
            duration: 7000,
            text: error.response.data.message,
          })
          return false
        })
    } catch (error) {
      console.log(error)
      commit('SHOW_LOADING', false)
      dispatch('showNotification', {
        title: 'Error',
        type: 'error',
        duration: 7000,
        text: error.response.data.message,
      })
      return false
    }
  },
}
