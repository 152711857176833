export default {
  getterListProductCategory: state => state.listCategory,
  getterListProduct: state => state.listProduct,
  getterListRegion: state => state.listRegion,
  getterListWPRegion: state => state.listWPRegion,
  getterListServer: state => state.listServer,
  getterListCluster: state => state.listCluster,
  getterListWPCluster: state => state.listWPCluster,
  getterListSize: state => state.listSize,
  getterListPlan: state => state.listPlan,
  getterListAttribute: state => state.listAttribute,
}
