export default {
  listCategory: [],
  listProduct: [],
  listRegion: [],
  listWPRegion: [],
  listServer: [],
  listCluster : [],
  listWPCluster : [],
  listSize : [],
  listPlan : [],
  listAttribute: [],
  accessToken: sessionStorage.getItem('accessToken'),
}
