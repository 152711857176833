export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/e-commerce/shop',
    name: 'apps-e-commerce-shop',
    component: () => import('@/views/apps/e-commerce/e-commerce-shop/ECommerceShop.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Shop',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/wishlist',
    name: 'apps-e-commerce-wishlist',
    component: () => import('@/views/apps/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  },
  // PRODUCT
  {
    path: '/apps/product/add/',
    name: 'apps-product-add',
    component: () => import('@/views/apps/product/product-add/ProductAdd.vue'),
  },
  {
    path: '/apps/product/list',
    name: 'apps-product-list',
    component: () => import('@/views/apps/product/product-list/ProductList.vue'),
  },

  {
    path: '/apps/region/add/',
    name: 'apps-region-add',
    component: () => import('@/views/apps/region/region-add/RegionAdd.vue'),
  },
  {
    path: '/apps/region/list',
    name: 'apps-region-list',
    component: () => import('@/views/apps/region/region-list/RegionList.vue'),
  },

  {
    path: '/apps/wp-region/add/',
    name: 'apps-wp-region-add',
    component: () => import('@/views/apps/wp-region/wp-region-add/WPRegionAdd.vue'),
  },
  {
    path: '/apps/wp-region/list',
    name: 'apps-wp-region-list',
    component: () => import('@/views/apps/wp-region/wp-region-list/WPRegionList.vue'),
  },
  {
    path: '/apps/wp-region/edit/:id',
    name: 'apps-wp-region-edit',
    component: () => import('@/views/apps/wp-region/wp-region-edit/WPRegionEdit.vue'),
  },

  {
    path: '/apps/cluster/add/',
    name: 'apps-cluster-add',
    component: () => import('@/views/apps/cluster/cluster-add/ClusterAdd.vue'),
  },
  {
    path: '/apps/cluster/list',
    name: 'apps-cluster-list',
    component: () => import('@/views/apps/cluster/cluster-list/ClusterList.vue'),
  },

  {
    path: '/apps/wp-cluster/add/',
    name: 'apps-wp-cluster-add',
    component: () => import('@/views/apps/wp-cluster/wp-cluster-add/WPClusterAdd.vue'),
  },
  {
    path: '/apps/wp-cluster/edit/:id',
    name: 'apps-wp-cluster-edit',
    component: () => import('@/views/apps/wp-cluster/wp-cluster-edit/WPClusterEdit.vue'),
  },
  {
    path: '/apps/wp-cluster/list',
    name: 'apps-wp-cluster-list',
    component: () => import('@/views/apps/wp-cluster/wp-cluster-list/WPClusterList.vue'),
  },

  {
    path: '/apps/server/add/',
    name: 'apps-server-add',
    component: () => import('@/views/apps/server/server-add/ServerAdd.vue'),
  },
  {
    path: '/apps/server/list',
    name: 'apps-server-list',
    component: () => import('@/views/apps/server/server-list/ServerList.vue'),
  },
  {
    path: '/apps/size/add/',
    name: 'apps-size-add',
    component: () => import('@/views/apps/size/size-add/SizeAdd.vue'),
  },
  {
    path: '/apps/size/list',
    name: 'apps-size-list',
    component: () => import('@/views/apps/size/size-list/SizeList.vue'),
  },
  {
    path: '/apps/size/edit/:id',
    name: 'apps-size-edit',
    component: () => import('@/views/apps/size/size-edit/SizeEdit.vue'),
  },
  {
    path: '/apps/plan/add/',
    name: 'apps-plan-add',
    component: () => import('@/views/apps/plan/plan-add/PlanAdd.vue'),
  },
  {
    path: '/apps/plan/list',
    name: 'apps-plan-list',
    component: () => import('@/views/apps/plan/plan-list/PlanList.vue'),
  },
  {
    path: '/apps/plan/edit/:id',
    name: 'apps-plan-edit',
    component: () => import('@/views/apps/plan/plan-edit/PlanEdit.vue'),
  },

  {
    path: '/apps/cluster-plan/add/',
    name: 'apps-cluster-plan-add',
    component: () => import('@/views/apps/cluster-plan/cluster-plan-add/ClusterPlanAdd.vue'),
  },
  {
    path: '/apps/cluster-plan/list',
    name: 'apps-cluster-plan-list',
    component: () => import('@/views/apps/cluster-plan/cluster-plan-list/ClusterPlanList.vue'),
  },
  {
    path: '/apps/cluster-plan/edit/:id',
    name: 'apps-cluster-plan-edit',
    component: () => import('@/views/apps/cluster-plan/cluster-plan-edit/ClusterPlanEdit.vue'),
  },

  // PRODUCT CATEGORY
  {
    path: '/apps/product-category/add/',
    name: 'apps-product-category-add',
    component: () => import('@/views/apps/product-category/product-category-add/ProductCategoryAdd.vue'),
  },
  {
    path: '/apps/product-category/list',
    name: 'apps-product-category-list',
    component: () => import('@/views/apps/product-category/product-category-list/ProductCategoryList.vue'),
  },
  {
    path: '/apps/product/:id',
    name: 'apps-product-list-attribute',
    component: () => import('@/views/apps/product/product-list/ProductListAttribute.vue'),
  },
  {
    path: '/apps/product/:id/attribute',
    name: 'apps-product-add-attribute',
    component: () => import('@/views/apps/product/product-add/ProductAddAttribute.vue'),
  },
  {
    path: '/apps/product-category/edit/:id',
    name: 'apps-product-category-edit',
    component: () => import('@/views/apps/product-category/product-category-edit/ProductCategoryEdit.vue'),
  },
  // ORDER
  {
    path: '/apps/setting/time-open',
    name: 'apps-setting-time-open',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/apps/settings/TimeOpen'),
  },
  {
    path: '/apps/setting/banner',
    name: 'apps-setting-banner',
    // eslint-disable-next-line import/extensions
    component: () => import('@/views/apps/settings/Banner'),
  },
]
