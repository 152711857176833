import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_SERVICE,
})
const axiosInstanceClient = axios.create({
  baseURL: process.env.VUE_APP_SERVICE_CLIENT_API,
})
const axiosSendGrid = axios.create({
  baseURL: 'https://client-monmi.myepis.cloud',
})
const axiosCloudService = axios.create({
  baseURL: process.env.VUE_APP_X_CLOUD_SERVICE,
})
export {
  // eslint-disable-next-line import/prefer-default-export
  axiosInstance, axiosInstanceClient,axiosSendGrid, axiosCloudService
}
